.thankyou_page {
    // height: 110vh;
    width: 100%;
    position: relative;
    //   background-image: linear-gradient(180deg, #21252903, #212529 85%), radial-gradient(ellipse at top left, #0d6efd80, transparent 50%), radial-gradient(ellipse at top right, #ffe48480, transparent 50%), radial-gradient(ellipse at center right, #712cf980, transparent 50%), radial-gradient(ellipse at center left, #d6338480, transparent 50%);   
    background: url(../../img/S_GYR_V1/hero-bg1.webp);
    background-size: cover;

    .cs_hero_wrap {
        background: none !important;
    }
    .text-color{
        color: #ffffffc4;
    }
    ul{
        li{
            list-style-type: disc;
            &::marker{
                font-size: 20px;
            }
        }
    }

    .pt-75{
        padding-top: 75px;
    }
.text-font{
    font-size: 30px !important;
}
    // Sample container
    .main-container {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }

    // Main CSS
    .check-container {
        width: 9.25rem;
        height: 10.5rem;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 568px) {
            width: 7.25rem;
            height: 8.5rem;
        }

        .check-background {
            width: 100%;
            height: calc(100% - 1.25rem);
            background: linear-gradient(to bottom right, #8760ff, #5337b9);
            box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
                0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
            transform: scale(0.84);
            border-radius: 50%;
            animation: animateContainer 0.75s ease-out forwards 0.75s;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;

            svg {
                width: 55%;
                transform: translateY(0.25rem);
                stroke-dasharray: 80;
                stroke-dashoffset: 80;
                animation: animateCheck 0.35s forwards 1.25s ease-out;
            }
        }

        .check-shadow {
            bottom: calc(-15% - 5px);
            left: 0;
            border-radius: 50%;
            background: radial-gradient(closest-side, #6849da, transparent);
            animation: animateShadow 0.75s ease-out forwards 0.75s;
        }
    }

    @keyframes animateContainer {
        0% {
            opacity: 0;
            transform: scale(0);
            box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
                0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
        }

        25% {
            opacity: 1;
            transform: scale(0.9);
            box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
                0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
        }

        43.75% {
            transform: scale(1.15);
            box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
                0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
        }

        62.5% {
            transform: scale(1);
            box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
                0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
        }

        81.25% {
            box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
                0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
        }

        100% {
            opacity: 1;
            box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
                0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
        }
    }

    @keyframes animateCheck {
        from {
            stroke-dashoffset: 80;
        }

        to {
            stroke-dashoffset: 0;
        }
    }

    @keyframes animateShadow {
        0% {
            opacity: 0;
            width: 100%;
            height: 15%;
        }

        25% {
            opacity: 0.25;
        }

        43.75% {
            width: 40%;
            height: 7%;
            opacity: 0.35;
        }

        100% {
            width: 85%;
            height: 15%;
            opacity: 0.25;
        }
    }



    // .cs_foot_style{
    //     @media (max-width: 768px) and (min-width: 410px) {
    //         height: 100vh !important;
    //     }
    //     @media (min-width: 1400px) {
    //         height: 100vh !important;
    //     }
    // }


    .tq_foot {
        @media (max-width: 1200px) and (min-width: 1200px) {
            position: fixed;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }

}