/*
*******************************************
Template Name: ACA Benefits
Author: Anush
Date: 19-09-2023
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************
*/

*,
*::before,
*::after {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
   list-style: none;
   list-style-type: none;
   text-decoration: none;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-rendering: optimizeLegibility;
}

html {
    scroll-behavior: smooth;
}

a{
    text-decoration: none;
}

// body {
//     padding: 0;
//     margin: 0;
//     box-sizing: border-box;
// }


.__GYRhome{
    font-family: "Manrope", sans-serif;
    .header_stk {
      background: #5820ff;
      overflow: hidden;
      height: 30px;
      position: relative;
    
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        overflow: hidden;
        width: 100%;
        height: 30px;
        // transform: translate(100%, 0);
    
        p {
          color: #ffffff;
          font-size: 14px;
          margin: 0;
        }
      }
    
      .ticker-wrapper__first-half, .ticker-wrapper__second-half {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        // animation: ticker 20s infinite linear forwards;
      }
    
      // .ticker-wrapper__second-half {
      //   animation: 20s ticker 20s/2 infinite linear forwards;
      // }
    }//header_stk
    
    @keyframes ticker {
      0% {
        transform: translate(100%, 0);
      }
    
      50% {
        transform: translate(0, 0);
      }
    
      100% {
        transform: translate(-100%, 0);
      }
    }

    header{
        // background: linear-gradient(252deg, #325cf5, #0003a5 -4%, #4056ff);
        padding: 20px 0;
        position: absolute;
        width: 100%;
        // background: #fff;
        z-index: 1;
        img {
          width: 100px;
      }//img
        .top_notch{
            // max-width: 1130px;
            padding: 0 20px;
            .nav_barACA{
                display: flex;
                align-items: center;
                @media (max-width: 568px) {
                    justify-content: center;
                }
            }//nav_barACA
        }//top_notch
    }//header        


/*--------------------------------------------------------------
11. Hero
----------------------------------------------------------------*/

.cs_hero.cs_style_1 {
    height: 100%;
    // padding-bottom: 115px;
    position: relative;
    .cs_hero_text {
      position: relative;
      z-index: 1;


      .animate__fadeInUp{
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      }
      // @keyframes fadeInUp {
      //   0% {
      //     opacity: 0;
      //     -webkit-transform: translate3d(0, 100%, 0);
      //     transform: translate3d(0, 100%, 0);
      //   }
      //   100% {
      //     opacity: 1;
      //     -webkit-transform: translateZ(0);
      //     transform: translateZ(0);
      //   }
      // }

      @keyframes fadeInUp {
        0% {
            opacity: 0;
            -webkit-transform: translate3d(0,100%,0);
            transform: translate3d(0,100%,0)
        }
    
        to {
            opacity: 1;
            -webkit-transform: translateZ(0);
            transform: translateZ(0)
        }
    }

      .animate__animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-duration: var(--animate-duration);
        animation-duration: var(--animate-duration);
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    }
    .cs_hero_wrap {
      height: 100%;
      width: 100%;
      padding-top: 130px;
      position: relative;
    //   background-image: linear-gradient(180deg, #21252903, #212529 85%), radial-gradient(ellipse at top left, #0d6efd80, transparent 50%), radial-gradient(ellipse at top right, #ffe48480, transparent 50%), radial-gradient(ellipse at center right, #712cf980, transparent 50%), radial-gradient(ellipse at center left, #d6338480, transparent 50%);   
      background: url(../img/S_GYR_V1/hero-bg1.webp);
      background-size: cover;
      @media (max-width: 1300px) {
        padding-top: 130px;
      }
      @media (max-width: 1199px) {
        padding-top: 130px;
      }
      @media (max-width: 568px) {
        padding-top: 100px;
      }
      @media only screen and (max-width: 1730px) and (min-width: 1198px) {
        // height: 800px;
      }
      > .container {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }


    .cs_hero_title {
      color: transparent;
      font-size: 48px;
      font-weight: 800;
      line-height: 1.1em;
      margin-bottom: 20px;
      background-image: linear-gradient(
        91.1deg,
        #9774ff,
        #ffffff,
        #ffffff,
        #9774ff
      );
      background-size: 200% 200%;
      background-clip: text;
      -webkit-background-clip: text;
      animation: move 5s linear infinite;
    //   max-width: 900px;
      @media (max-width: 1700px) {
        font-size: 45px;
      }
      @media (max-width: 1199px) {
        font-size: 36px;
      }
      @media (max-width: 1199px) {
        margin-bottom: 20px;
      }
      @media (max-width: 568px) {
        font-size: 26px !important;
        text-align: center;
      }
    }

    @-webkit-keyframes move {
        0% {
          background-position: 0% center;
        }
        100% {
          background-position: -200% center;
        }
      }
      @-moz-keyframes move {
        0% {
          background-position: 0% center;
        }
        100% {
          background-position: -200% center;
        }
      }
      @keyframes move {
        0% {
          background-position: 0% center;
        }
        100% {
          background-position: -200% center;
        }
      }


    .cs_hero_subtitle {
      max-width: 700px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffffc4;
      margin-bottom: 29px;
      display: flex;
      span{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 100%;
          width: 25px;
          height: 25px !important;
          margin-right: 5px;
          height: fit-content;
        }
      }
    //   margin-bottom: 110px;
      @media (max-width: 1700px) {
        max-width: 600px;
      }
      @media (max-width: 1199px) {
        max-width: 580px;
      }
      @media (max-width: 568px) {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        justify-content: center;
      }
    }

    .lit_sm{
      @media (max-width: 568px) {
        font-size: 15px !important;
        font-weight: 500 !important;
        text-align: center;
        justify-content: center;
      }
    }



    .pre_cs_btn_dialNum{
        padding-bottom: 40px;
        @media (max-width: 568px) {
            padding-bottom: 40px;
        }
        .custom_clickBtn{
            box-shadow: 0 0 14px -7px #193df0;
            background-image: linear-gradient(180deg, #a98dff 0%, #5820ff 100%);
            cursor: pointer;
            color: #fff;
            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
            text-decoration: none;
            padding: 14px 20px;
            font-weight: 700;
            font-size: 21px;
            border-radius: 50px;
            border-width: 2px;
            border-style: none;
            letter-spacing: 0;
            text-transform: none;
            text-shadow: 0 0 0 transparent;
            width: 60%;
            position: relative;
            @media (max-width: 768px) {
                width: 80%;
                margin: 0 auto;
            }
            @media (max-width: 568px) {
                width: 100%;
            }
            &:before{
                content: "";
                background: url(../img/S_GYR_V1/arrow-narrow-right.svg);
                width: 36px;
                position: absolute;
                z-index: 2;
                top: 5px;
                right: 20px;
                filter: invert(1);
                transition: .3s;
                height: 50px;
                background-repeat: no-repeat;
                background-size: 25px;
                background-position: right;
            }

            &:hover::before{
                transform: rotate(-24.5deg) scale(1.3);
            }
        }//custom_callBtn

        .zoom-in-zoom-out {
          animation: zoom-in-zoom-out 2s ease-out infinite;
        }
        
        @keyframes zoom-in-zoom-out {
          0% {
            transform: scale(0.9, 0.9);
          }
          50% {
            transform: scale(1, 1);
          }
          100% {
            transform: scale(0.9, 0.9);
          }
        }

        .button--anim {
          transition: all ease-in-out 300ms;
          -webkit-animation: animation-blue-flash 1.25s linear infinite normal;
          animation: animation-blue-flash 1.25s linear infinite normal;
        }

        @keyframes animation-blue-flash {
          0% {
            -moz-box-shadow: 0 0 0 0 #5820ff;
            box-shadow: 0 0 0 0 #5820ff;
          }
          70% {
              -moz-box-shadow: 0 0 0 10px rgba(2, 103, 193, 0);
              box-shadow: 0 0 0 15px rgba(2, 103, 193, 0);
          }
          100% {
              -moz-box-shadow: 0 0 0 0 rgba(2, 103, 193, 0);
              box-shadow: 0 0 0 0 rgba(2, 103, 193, 0);
          }
        }
    }//pre_btn_dialNum
  

    .cs_hero_info_wrap {
      margin-bottom: -110px;
      background: #fff;
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      position: relative;
      z-index: 2;
      @media (max-width: 1700px) {
        // padding: 60px 30px;
      }
      @media (max-width: 1199px) {
        // padding: 30px 25px;
        gap: 24px;
      }
      @media (max-width: 991px) {
        justify-content: center;
      }
      @media (max-width: 767px) {
        justify-content: space-between;
      }
      @media (max-width: 575px) {
        flex-direction: column;
        align-items: flex-start;
        // padding: 35px 45px;
      }

      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        max-width: 1200px;
        margin: 20px 0 40px;
        @media (max-width: 575px) {
          gap: 24px;
        }  
    
        .card {
          position: relative;
          min-width: 320px;
          height: 245px;
          box-shadow: 0px 0px 42px -47px rgba(0, 0, 0, 0.62);
          border: none !important;
          border-radius: 15px;
          // margin: 20px;
          transition: 0.5s;
    
          &:nth-child(1) .box .content a {
            // background: #2196f3;
          }
    
          &:nth-child(2) .box .content a {
            // background: #e91e63;
          }
    
          &:nth-child(3) .box .content a {
            // background: #23c186;
          }
    
          .box {
            position: absolute;
            // top: 20px;
            left: 20px;
            right: 20px;
            // bottom: 20px;
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            transition: 0.5s;
    
            &:hover {
              transform: translateY(-20px);
            }
    
            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 50%;
              height: 100%;
              // background: rgba(255, 255, 255, 0.03);
            }
    
            .content {
              padding: 20px;
              text-align: center;
    
              img {
                width: auto;
                height: 85px;
              }
    
              h3 {
                font-size: 1rem;
                color: #222222;
                z-index: 1;
                font-weight: 600;
                transition: 0.5s;
                margin-bottom: 10px;
                margin-top: 16px;
              }
    
              p {
                font-size: 13px;
                font-weight: 300;
                color: rgba(105, 105, 105, 0.9);
                z-index: 1;
                transition: 0.5s;
              }
            }
          }
        }
      }
      
    }

    .cs_shadow_1 {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }

    .cs_hero_info {
      gap: 8px;
      @media (max-width: 1199px) {
        gap: 8px;
      }
    }

    .img_float {
        display: flex;
        align-items: center; // Center alignment for all items
        justify-content: center; // Center alignment for all items
        position: relative;
        overflow: hidden; // Ensure no overflow
      
        .parallax {
          position: relative;
          transform-style: preserve-3d;
          backface-visibility: hidden;
          pointer-events: none;
          width: 100%; // Ensure it takes the full width
          height: auto; // Adjust height accordingly
          @media (max-width: 568px) {
            max-width: 380px !important;
          }
          .parallax-layer {
            position: relative;
            transform-style: preserve-3d;
            backface-visibility: hidden;
            display: block;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
      
            .cs_hero_img {
              display: block;
              height: auto;
              max-width: 100%;
              width: 100%;
              // height: 100%;
              object-fit: cover; // Ensure images cover the container appropriately
            }
          }
        }
      }//img_float
      


      
      .fadein {
        animation: fadein 500ms ease-out both;
      }
      
      @keyframes fadein {
        from {
          opacity: 0;
          scale: 0.8;
        }
        to {
          opacity: 1;
          scale: 1;
        }
      }

  }


  /*--------------------------------------------------------------
10. form design
----------------------------------------------------------------*/

form.form_display{
  width: 100%;
  padding: 0 0 60px 0;

  .secure-img{
    width: calc(100% - 60px);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .form-content{
    padding: 2rem 4rem 2rem 4rem;
    backdrop-filter: blur(8px);
    box-sizing: border-box;
    box-shadow: 2px 2px 30px rgba(black, 0.4);
    position: relative;
    border-radius: 1em;
    @media (max-width: 600px) {
      padding: 2rem 2rem !important;
    }
    &::before {
      position: absolute;
      background: rgb(254,254,254);
      background: linear-gradient(329deg, rgba(254,254,254,0.09) 0%, rgba(255,255,255,0.11) 100%);
      content:"";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 1em;
      filter: blur(0.5px);
    }
    
    &::after {
      position: absolute;
      content:"";
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      top: 4px;
      left: 4px;
      z-index: -1;
      border-radius: 1em;
      filter: blur(0.2px);
      border: 1px dotted rgba(white, 0.2);
    }
  }

  .form-view {
    // display: none;
    margin: 24px auto;

    // ======= Feild Design ========
    .field {
      display: block;
      margin: 0 0 15px 0;
      width: 100%;

      #email_address, #telephone_number{
        width: calc(100% - 60px);
        // float: left;
        border: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        -webkit-border-top-left-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      span.secure {
        height: 50px;
        background: #e9e9e9;
        width: 60px;
        color: #a690fc;
        float: right;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        span {
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 10px;
          display: inline-grid;
          line-height: 100%;
          text-align: center;
          text-transform: uppercase;
          font-weight: 700;
          img{
            margin: 0 0 5px 12px;
          }
        }
      }

      .secure-green{
        font-size: 11px;
        padding: 5px 10px;
        border: 1px solid #00c6a6;
        border-radius: 82px;
        color: #00c6a6;
      }

      .padding16p{ 
        padding: 16px ;
        padding-bottom: 0px; 
        padding-top: 15px;
        .violot-button2{ 
          background: transparent !important;
          border-bottom: 1px solid #8760ff !important;
          border-radius: 0px !important;
        }
        .violot-button{ 
          background: rgb(135,96,255);
          background: linear-gradient(90deg, rgba(135,96,255,1) 0%, rgba(132,100,248,1) 35%);
          color: #fff;
          box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
          transition: background-color 0.3s ease-in-out !important;
          transition: all .4s ease-in-out;
          display: block;
          border-radius: 5px;
          input {
            appearance: none;
            margin: 0;
        }
        
        
  
        input[type=checkbox] {
            display: block;
        }
  
        input[type=checkbox]+label {
            background: url(../img/S_GYR_V1/img2.png) no-repeat left center;
            background-position: 15px;
            background-size: 20px;
            border-radius: 3px;
            color: #383838;
            cursor: pointer;
            border: 1px #FFF solid;
            display: inline-block;
            padding: 10px 0;
            width: 100%;
            text-align: left;
            padding-left: 40px;
        }
  
  
        input[type=checkbox]:checked+label {
            background: url(../img/S_GYR_V1/img4.png) no-repeat left center;
            background-position: 15px;
            background-size: 20px;
            cursor: pointer;
            display: inline-block;
            color: #fff;
        }
  
        .test-1{ 
          width: 0px !important; 
          height: 0 !important;
        }     
        .selected-false{ 
          color: white !important; 
          font-weight: 900 !important;
          font-size: 16px; 
          font-weight: 500;
          height: 50px;
          display: flex;
          align-items: center;
        }  
        }
  
      }

    
      .lender-list {
        li {
          &.current {
            .name {
    
              .circle {
                background: #000 url(../img/S_GYR_V1/tick02.png) no-repeat center center;
                border-color: #000;
              }
            }
    
          }
    
          .name {
            background-image: linear-gradient(to right, #8760ff, #8464f8, #5337b9, #3e1fad);
            color: #fff;
            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
            border-color: #ddd !important;
            transition: background-color 0.3s ease-in-out !important;
            background-size: 300% 100%;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
            padding: 7px 15px;
            height: 50px;
            font-size: 16px;
            display: block;
            margin-bottom: 15px;
            border-radius: 5px;
            font-weight: bold;
            line-height: 30px;
            cursor: pointer;
    
    
            .circle {
              border: 1px solid #ffffff;
              width: 22px;
              height: 22px;
              border-radius: 50%;
              display: inline-block;
              margin: 0px 8px 0px 0;
              position: relative;
              top: 5px;
              background: none;
              padding: 0;
    
            }
          }
        }
    
        &.lender-list-two {
          li {
            .name.lend_2 {
              border: 0;
              border-radius: 0;
              border-bottom: 1px solid #8564fa !important;
              padding: 4px 0;
              background-image: none !important;
            }
          }
        }
      }
    
      .more-lenders {
        cursor: pointer;
        u{
          text-decoration: underline !important;
        }
      }

      .circle {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: stroke 5s ease-out infinite forwards;
      }

      @keyframes stroke {
        to {
          stroke-dashoffset: 0;
        }
      }

    }// FIELD

    .progress_bar{
      .progress-bar-line {
        width: 100%;
        height: 15px;
        background: #FFFFFF;
        display: block;
        clear: both;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        overflow: hidden;
        margin: 0 0 30px 0;
        position: relative;
        span {
          position: absolute;
          top: 0;
          left: 0;
          background:#00C6AF;
          height: 15px;
          color: #FFFFFF;
          padding: 0 10px 0 0;
          text-align: right;
          line-height: 15px;
          -webkit-border-radius: 50px;
          -moz-border-radius: 50px;
          border-radius: 50px;
          }
        .load{
          animation: load 2s normal forwards;
          -webkit-animation: load 2s normal forwards;
        }
        .load1{
          animation: load1 2s normal forwards;
          -webkit-animation: load1 2s normal forwards;
        }
        .load2{
          animation: load2 2s normal forwards;
          -webkit-animation: load2 2s normal forwards;
        }
        .load3{
          animation: load3 2s normal forwards;
          -webkit-animation: load3 2s normal forwards;
        }
        .load4{
          animation: load4 2s normal forwards;
          -webkit-animation: load4 2s normal forwards;
        }
      }
    }// PROGRESS_BAR
    @keyframes load {
      0% {
        width: 0;
      }
      100% {
        width: 20%;
      }
    }
    
    @keyframes load1 {
      0% {
        width: 0;
      }
      100% {
        width: 40%;
      }
    }
    
    @keyframes load2 {
      0% {
        width: 0;
      }
      100% {
        width: 60%;
      }
    }
    
    @keyframes load3 {
      0% {
        width: 0;
      }
      100% {
        width: 80%;
      }
    }
    
    @keyframes load4 {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  } //FORM VIEW
  

  .Mod_detail, .Mod_detail1, .Mod_detail2{
        // display: none;
        text-transform: uppercase;
        background: #ffffff;
        color: #10033a;
        margin: 0px 0 20px;
        text-align: left;
        line-height: 140%;
        border-radius: 5px;
        .ADD_na_top{
            font-size: 13px;
            font-weight: 700;
            margin-bottom: 5px;
        }//ADD_na_top
        .ADD_clr_typ{
            font-size: 12px;
            margin-bottom: 0;
            font-weight: 700;
        }//ADD_clr_typ
    }//ADD_detail

    .return_back, .return_back1, .return_back2{
        font-size: 10px;
        font-weight: 700;
        // display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .pcode_btn{
          background: none;
          border: none;
          color: #fff;
          text-decoration: underline;
        }
        .float-right{
          float: right !important;
        }
        a{
          color: #fff;
          text-decoration: none;
        }
        p{
          cursor: pointer;
        }
        span{
            text-decoration: underline;
        }
    }///return_back


  ul.wpforms-field-required {
    display: grid;
    gap: 15px;
    clear: both;
    width: 100%;
    list-style: none;
    padding: 0;
    padding-top: 8px !important;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr !important;
    }
    li {
      height: unset;
      line-height: 16px;
      display: flex;
      align-items: flex-start;
      margin: 0;
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }


input[type=radio] {
  display: none !important;
}

input[type=radio] + label {
    text-align: center;
    width: 100% !important;
    font-size: 1.25rem !important;
    padding: 12.5px 20px !important;
    background-image: linear-gradient(to right, #8760ff, #8464f8, #5337b9, #3e1fad);
    cursor: pointer;
    color: #fff;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    border-color: #ddd !important;
    transition: background-color 0.3s ease-in-out !important;
    border-radius: 6px !important;
    background-size: 300% 100%;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

input[type=radio]:checked + label, input[type=radio] + label:hover {
  background-position: 95% 0;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  color: #ffffff !important;
  cursor: pointer !important;
} 


  label {
    font-weight: 500;
    margin: 0 0 15px 0;
    font-size: 20px;
    display: block;
    clear: both;
    color: #FFFFFF;
    line-height: 160%;
    @media (max-width: 568px) {
      font-size: 18px;
    }
    em{
      color: #d63637;
      font-weight: 500;
    }
    span {
      font-size: 14px;
      display: block;
      clear: both;
      font-weight: 400;
      opacity: 0.75;
      line-height: 160%;
      padding: 5px 0 0 0;
    }
    i{
      font-size: 12px;
      font-weight: 500;
    }
  }

.top_line{
  font-size: 13px;
}

  label span.warning, ul.requirements {
    background: #00C6AF;
    opacity: 1;
    color: #ffffff;
    padding: 15px 20px;
    margin: 20px auto;
    font-weight: 500;
    display: table;
    border-radius: 50px;
  }

  .button.yellow {
    background-image: linear-gradient(to right, #25aae1, #00C6AF, #03a895, #009180);
    color: #ffffff;
  }


  #idpc_error_message, .error, #signature_base-error, span.error {
    background: #FB3640;
    padding: 5px 15px;
    line-height: 130%;
    color: #FFFFFF!important;
    font-weight: 400;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font-size: 12px;
    // display: none;
    // display: table;
    clear: both;
    margin: 5px 0 0 0;
    position: relative;
    &:before{
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #FB3640;
      position: absolute;
      top: -5px;
      content: '';
      display: none;
      // display: block;
      left: 30px;
      z-index: 10;
      width: 0;
      height: 0;
    }
  }

  select {
    background: #FFFFFF url(../img/S_GYR_V1/select.png) no-repeat right center !important;
    background-size: 60px 60px !important;
    -webkit-background-size: 60px 60px !important;
    -moz-background-size: 60px 60px !important;
    -o-background-size: 60px 60px !important;
    cursor: pointer;
    padding: 0 60px 0 20px !important;
    font-weight: 400;
  }




  input, #idpc_button1, #idpc_button2, #idpc_button3, #idpc_button4, #idpc_button5, select, .sign-here, .clear-btn {
    width: 100%;
    height: 50px;
    outline: none;
    border-radius: 5px;
    appearance: none;
    padding: 0 25px;
    font-size: 15px;
    font-weight: 400;
    color: #082954;
    background: #FFFFFF;
    border: 0;
  }

  .sign_here{
    #signature-pad {
      border: 1px solid #ccc;
      background: #fff;
      color: #fff;
      border-radius: 5px;
      position: relative;
      height: 265px;
      margin-bottom: 10px;
    }
    
    #signature-canvas {
        width: 100%;
        height: 100%;
    }
    
    button {
        padding: 5px 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        font-size: 14px;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
    }
    
    button#clear {
        background-color: #6c757d;
    }
    
    button:hover {
        opacity: 0.8;
    }
    p{
      font-size: 14px;
    }
    ul {
        list-style-type: disc;
        font-size: 14px;
        li{
          list-style: disc !important;
          margin-bottom: 10px;
        }
    }
    
    a {
        color: #AA8FFD;
        text-decoration: underline;
    }
    
    a:hover {
        color: #855dff;
        text-decoration: underline;
    }
    .sign-canva-div{
      overflow: hidden !important;
    }
  }// signature

  .clear-btn{
    background: rgb(231, 231, 231);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 0;
    padding: 10px 25px;
    height: auto;
    .msf-nav-button.clear{
      padding: 4px 8px;
      background: #c7c7c7;
      color: #082954;
      font-size: 13px;
      font-weight: 600;
    }
  }

  .sml-font{
    font-size: 13px;
  }

  .dropdown{
    width: 100%;
  }


  #idpc_button1, #idpc_button2, #idpc_button3{
    background-image: linear-gradient(to right, #8760ff, #8464f8, #5337b9, #3e1fad);
    box-shadow: rgba(0, 0, 0, 0.274) 0px 2px 8px 0px;
    color: #FFFFFF;
    border: 0;
    padding: 0 15px;
    font-size: 18px;
    height: 50px;
    margin: 0;
    cursor: pointer;
    font-weight: 500;
  }

  #idpc_dropdown1, #idpc_dropdown2, #idpc_dropdown3{
    animation: hellopostcode 1s linear 3 normal;
    margin: 0;
  }


  .button {
    display: flex;
    align-items: center;
    height: 48px;
    width: auto;
    min-width: 120px;
    padding: 0 20px;
    color: #FFFFFF;
    font-size: 18px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    clear: both;
    background-size: 300% 100%;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    justify-content: center;
    &:hover {
      background-position: 95% 0;
      -o-transition: all .4s ease-in-out;
      -webkit-transition: all .4s ease-in-out;
      transition: all .4s ease-in-out;
    }
    &:focus {
      outline: none;
    }
  }// BUTTON

  .previous_btnSign{
    height: 60px !important;
    width: 100%;
    background: #000 !important;
    @media (max-width: 768px) {
      width: 100%;
      min-width: unset;
      height: 60px !important;
      padding: 0 10px !important; 
      font-size: 15px !important;
    }

    @media (max-width: 468px) {
      font-size: 14px !important;
    }

  }

  .submit {
    width: 100% !important;
    height: 60px;
    border-radius: 5px !important;
    text-align: center;
  }

  .lenderbutton{
    display: none;
    position: sticky;
    bottom: 0;
    @media (max-width: 768px) {
      // display: block;
    }
  }

  .button.color-1 {
    background-image: linear-gradient(to right, #25aae1, #00C6AF, #03a895, #009180);
  }


  .button.back {
    font-size: 14px;
    padding: 20px 0;
    font-weight: 500;
    color: #ffffff !important;
  }

  .button {
    outline: none;
    appearance: none;
    border: 0;
    margin: 0;
  }


    .field.previous-address span.add-remove {
      cursor: pointer;
      margin: 25px 0 0 0;
      float: left;
      width: 50%;
      font-weight: 500;
      img{
        padding: 0 10px 0 0;
      }
    }
    span.add-remove.remove {
      text-align: right;
    }

    .in-out {
      -webkit-animation: in-out 1s infinite alternate;
      animation: in-out 1s infinite alternate;
    }

  .form-sub_btn {
    text-align: center;
    clear: both;
    display: flex;
    width: 100%;
    padding: 15px 0 0 0;
    justify-content: space-between;
  }
  

  // input [type=radio]:checked + .label-2 { 
  //   background-image: linear-gradient(to right, #512fce, #3e1fad) !important;
  // }

  .current-checked { 
    background-image: linear-gradient(to right, #512fce, #3e1fad) !important;
  }
  
  // form-sub_btn
}// FORM DISPLAY MAIN FORM

.qstn-label{
  text-align: center;
  width: 100% !important;
  font-size: 1.25rem !important;
  padding: 12.5px 20px !important;
  background-image: linear-gradient(to right, #8760ff, #8464f8, #5337b9, #3e1fad);
  cursor: pointer;
  color: #fff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-color: #ddd !important;
  transition: background-color 0.3s ease-in-out !important;
  border-radius: 6px !important;
  background-size: 300% 100%;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  &:hover{
    background-image: linear-gradient(to right, #512fce, #3e1fad);
  }
}



/*--------------------------------------------------------------
10. accordion
----------------------------------------------------------------*/
.sec_3Disgn{
  padding-top: 115px;
  background: url(../img/S_GYR_V1/hero-bg.svg) no-repeat center center;
  background-size: cover;
  // font-family: "Beausite Slick Trial Light";
  .acco_desin{
      margin: 40px 0;
      a{
        text-decoration: none !important;
      }
        .accordion {
          width: 100%;
          h2{
              font-size: 26px;
              @media screen and (max-width: 510px) {
                  font-size: 22px;
              }
          }
          .accordion-list {
            padding: 0;
            margin-bottom: 12px;
            border: 0;
            
            .accordion-item {
              border: 0;
              box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(11, 15, 25, 0.06), 0 0.125rem 0.4rem -0.0625rem rgba(11, 15, 25, 0.03) !important;
              .accordion-button{
                border: 0;
                color: #150052 !important;
                background: #ffffff;
                &::after{
                  background-image: url("../img/plus-icon.png") !important;
                  background-position: center -16px !important;
                  background-position: center top !important;
                  transition: initial !important;
                  background-size: 14px !important;
                }
              }
              .accordion-button:not(.collapsed):after {
                background-image: url("../img/plus-icon.png") !important;
                background-position: center -15px !important;
                background-size: 14px !important;
              }
              .accordion-body{
                color: grey;
                p{
                  margin-bottom: 10px;
                }
              }
            }
            
            
          }
        }
    }
}

/*--------------------------------------------------------------
11. footer
----------------------------------------------------------------*/  

footer{
    box-shadow: -5px -12px 34px -4px rgba(0,0,0,0.1);
    background-color: #0b0f19 !important;
    color: #fff;
    p{
        font-size: 12px;
        margin-bottom: 30px;
        word-spacing: 2px;
        letter-spacing: 1px;
        .foot{
            text-decoration: underline;
            color: blue;
        }
        a{
            text-decoration: none;
            color: unset;
        }
    }
    img{
      width: 213px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }
}//footer


@media screen and (max-width: 780px){
    .main_bdy{
        padding: 0px !important;
        .fullSection{
            padding: 0px !important;
        }
    }
    .point_lokCrzy{
        width: 45% !important;
    }

}

    @media screen and (min-width: 0px) and (max-width: 480px){
        .btn_clickcall{
            button {
                padding: 6px 20px !important;
            }
        } 
        .point_lokCrzy{
            width: 48% !important;
        }
        .mob_Wd100{
            width: 100% !important;
        }
        header {
            img {
                width: 90px !important;
            }
        }
        .pre_contentH1 {
            padding: 0px 0px 20px !important;
        }
        .inner {
            padding: 20px 0 !important;
        }
    }


}//__GYRhome